export default {
  props: {
    msgAreaList: { // 发送类型
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      wxAppName: '', // 选中的小程序
      departmentList: [], // 部门list
      studentList: [],
      wxAppIdList: [], // 小程序原始id
      formData: {
        sendType: '',
        sendRange: '',
        // campusId: '', // 校区
        collegeId: this.$store.state.login.userInfo.collegeId || '', // 院系
        deptId: '', // 部门
        majorId: '', // 专业
        classesId: '', // 班级
        grade: '', // 年级
        msgArea: 0, // 推送消息范围 0-全校;1-个人; 2-班;3-专业;4-院;5-部门; 6-校区
        msgStatus: 0, // 消息状态 0 未读 1 已读 2 已处理
        msgType: 1, // 消息类型 1-通用 5-文章链接 6-小程序
        userType: 0, // 推送人群 0全部 2或null 学生 3教师
        value: '', // 用于远程搜索
        userId: '', // 用户id
        linkUrl: '',
        wxAppId: '',
        wxAppUrl: '',
        enable: 1, // 是否弹窗 0-否 1-是
        remarks: ''
      },
      rules: {
        msgArea: {
          required: true,
          message: '请选择推送范围',
          trigger: 'blur'
        },
        userType: {
          required: true,
          message: '请选择推送人群',
          trigger: 'blur'
        },
        majorId: {
          required: true,
          message: '请选择接收专业',
          trigger: 'blur'
        },
        userId: {
          required: true,
          message: '请选择接收人',
          trigger: 'blur'
        },
        linkUrl: [{
          required: true,
          message: '请输入文章链接',
          trigger: 'blur'
        },
          {
            max: 300,
            message: '不能超过300字',
            trigger: 'blur'
          }],
        wxAppId: [{
          required: true,
          message: '请输入小程序的原始ID',
          trigger: 'blur'
        },
          {
            max: 300,
            message: '不能超过300字',
            trigger: 'blur'
          }],
        wxAppUrl: [{
          required: true,
          message: '请输入小程序的路径',
          trigger: 'blur'
        },
          {
            max: 300,
            message: '不能超过300字',
            trigger: 'blur'
          }]
      }
    }
  },
  async mounted () {
    this.preTitle = '推送'
    await Promise.all([
      this.getCollegeList(),
      this.getDepartmentList(),
      this.$http.getParamListByKey('wxAppId', this, 'wxAppIdList')
    ])
    await this.$nextTick()
    if (!this.id) {
      if (this.$store.state.login.userInfo.collegeId) {
        await this.getMajorList()
      }
      this.setInitData()
    }
  },
  methods: {
    /*
    * 判断推送范围 全校/专业/学院/个人 1、个人；2、班；3、专业；4、院；5、校区
    * 动态设置rules
     */
    handleMsgArea (val) {
      switch (val) {
        case 0: // 全校
          this.formData.grade = ''
          this.formData.collegeId = ''
          this.formData.majorId = ''
          this.formData.userId = ''
          this.formData.value = ''
          this.formData.deptId = ''
          break
        case 4: // 学院
          this.formData.majorId = ''
          this.formData.userId = ''
          this.formData.value = ''
          this.formData.deptId = ''
          break
        case 3: // 专业
          this.formData.userId = ''
          this.formData.value = ''
          // 清空’全二级学院‘的选择，否则无法加载majorList
          // this.clearList(this.formData.collegeIds)
          this.formData.collegeId = ''
          this.formData.userType = 2
          this.formData.deptId = ''
          break
        case 1: // 个人
          this.formData.grade = ''
          this.formData.collegeId = ''
          this.formData.majorId = ''
          this.formData.deptId = ''
          this.formData.userType = this.formData.userType || 3
          break
        case 5: // 部门
          this.formData.userType = 3
          this.formData.grade = ''
          this.formData.collegeId = ''
          this.formData.majorId = ''
          this.formData.value = ''
          this.formData.userId = ''
          break
      }
      this.$refs.form.clearValidate()
    },
    // 推送给教师时，去掉年级字段
    handleUserType (val) {
      if (val === 3) this.formData.grade = ''
    },
    // 切换校区
    /* changeCampus (campusId) {
      this.formData.collegeId = ''
      this.formData.majorId = ''
      this.formData.classesId = ''
      this.getCollegeList(campusId)
    }, */ // 切换院系 全二级学院时 使用collegeIds数组
    changeCollege (collegeId) {
      this.formData.majorId = ''
      this.formData.userId = ''
      this.clearList(this.majorList)
      if (collegeId) this.getMajorList({ collegeId: this.formData.collegeId })
    },
    // 切换专业
    changeMajor () {
      this.formData.userId = ''
    },
    // 获取部门
    async getDepartmentList () {
      try {
        const res = await this.$http.getDepartmentListQuery({})
        this.departmentList = res.data
      } catch (e) {
      }
    },
    handleMsgType () {
      this.formData.wxAppUrl = ''
      this.formData.wxAppId = ''
      this.formData.linkUrl = ''
      this.$refs.form.clearValidate()
    },
    handleWxAppId (val) {
      if (val) {
        const _result = this.wxAppIdList.filter(({ id }) => id === val)[0]
        this.formData.wxAppUrl = _result.remarks
        this.formData.wxAppId = _result.paramValue
      } else {
        this.formData.wxAppUrl = ''
        this.formData.wxAppId = ''
      }
    },
    // 远程搜索 获取用户id
    handleSelect (user) {
      // 2-学生
      if (this.formData.userType === 2) {
        this.formData.userId = user.userId
      } else {
        this.formData.userId = user.id
      }
      this.userSelectDisabled = true
      this.$refs.form.validateField('userId')
    }
  }
}
