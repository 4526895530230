<template>
  <el-dialog
    class="notice-dialog"
    title="微信小程序原始ID管理"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="920px"
    :before-close="close"
  >
    <table-view
      :show-page="false"
      style="padding-top: 0;padding-right: 0;">
      <template #header>
        <div class="mb-1" style="text-align: right">
          <el-button v-permission="['app:notice:edit']" size="small" type="primary" @click="newRow"
                     style="margin-right: 1rem;">新增小程序原始ID
          </el-button>
        </div>
      </template>
      <el-form ref="formRef" :model="formData" :rules="rules">
        <el-table :data="formData.tableData" height="400px">
          <el-table-column label="小程序名" width="180px">
            <template v-slot="scope">
              <el-form-item v-if="scope.row.isEditing" :prop="`tableData.${scope.$index}.paramName`"
                            :rules="rules.paramName">
                <el-input size="small" v-model.trim="scope.row.paramName" />
              </el-form-item>
              <span v-else>{{ scope.row.paramName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="小程序原始ID" min-width="160">
            <template v-slot="scope">
              <el-form-item v-if="scope.row.isEditing" :prop="`tableData.${scope.$index}.paramValue`"
                            :rules="rules.paramValue">
                <el-input size="small" v-model="scope.row.paramValue" />
              </el-form-item>
              <span v-else>{{ scope.row.paramValue }}</span>
            </template>
          </el-table-column>
          <el-table-column label="小程序链接" min-width="220">
            <template v-slot="scope">
              <el-form-item v-if="scope.row.isEditing" :prop="`tableData.${scope.$index}.remarks`"
                            :rules="rules.remarks">
                <el-input size="small" v-model="scope.row.remarks" />
              </el-form-item>
              <span v-else>{{ scope.row.remarks }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="160px">
            <template v-slot="scope">
              <template v-if="scope.row.isEditing">
                <el-form-item>
                  <el-button size="small" @click="cancel(scope)">取消</el-button>
                  <el-button type="primary" size="small" v-if="scope.row.isEditing" @click="save(scope)">保存
                  </el-button>
                </el-form-item>
              </template>
              <template v-else>
                <template v-if="!isEditing">
                  <el-button v-permission="['app:notice:del']" size="small" @click="delRow(scope.row.id)">
                    删除
                  </el-button>
                  <el-button v-permission="['app:notice:edit']" size="small" type="primary" plain
                             @click="editRow(scope)">编辑
                  </el-button>
                </template>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </table-view>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import tableView from '@/vue/mixins/table-view'
import Vue from 'vue'
import { addParams, deleteParams } from '@/api/sysParams'

export default {
  name: 'wxAppIdDialog',
  mixins: [dialog, tableView],
  props: {
    addData: {
      type: Object
    }
  },
  data () {
    return {
      formData: {
        tableData: []
      },
      rules: {
        paramName: [
          {
            required: true,
            message: '请填写小程序名',
            trigger: 'blur'
          }, {
            max: 30,
            message: '不能超过30个字',
            trigger: 'blur'
          }
        ],
        paramValue: [{
          required: true,
          message: '请填写小程序原始ID',
          trigger: 'blur'
        }, {
          max: 60,
          message: '不能超过60个字',
          trigger: 'blur'
        }],
        remarks: {
          max: 200,
          message: '不能超过200个字',
          trigger: 'blur'
        }
      },
      isEditing: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.renderTable()
    })
  },
  methods: {
    newRow () {
      if (this.isEditing) {
        this.$message('请先保存已修改的数据，再进行新增操作！')
      } else {
        const _row = {
          paramValue: '',
          paramName: '',
          paramType: 2, // 系统字典类型。禁止修改，因此不显示
          remarks: '',
          paramKey: 'wxAppId', // 禁止修改，因此不显示
          isEditing: true
        }
        this.formData.tableData.unshift(_row)
        this.isEditing = true
      }
    },
    editRow (scope) {
      this.isEditing = true
      scope.row.isEditing = true
      Vue.set(this.formData.tableData, scope.$index, scope.row)
    },
    async renderTable () {
      try {
        const res = await this.$http.getSysDictionaryByKey('wxAppId')
        this.formData.tableData = res.data
        this.formData.tableData.map(row => Object.assign(row, { isEditing: false }))
      } catch (e) {
      }
    },
    delRow (id) {
      this.$confirm('点击确定将删除小程序原始ID。', '删除').then(async () => {
        try {
          await deleteParams(id)
          await this.renderTable()
        } catch (e) {
        }
      })
    },
    // 取消
    async cancel (scope) {
      scope.row.isEditing = false
      this.isEditing = false
      Vue.set(this.formData.tableData, scope.$index, scope.row)
      await this.renderTable()
    },
    // 保存
    save (scope) {
      const _duplicateKeys = this.formData.tableData.filter(({ remarks }) => remarks === scope.row.remarks)
      if (_duplicateKeys.length > 1) {
        this.$message.error('已存在相同的小程序链接！')
        return
      }
      this.$refs.formRef.validate(valid => {
        if (valid) {
          this.$confirm('点击确定保存小程序原始ID数据。', '保存').then(async () => {
            try {
              await addParams(scope.row)
            } catch (e) {
            }
          }).catch(() => {
            this.cancel(scope)
          }).finally(() => {
            this.renderTable()
            this.isEditing = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
